<template>
  <div class="bills-conatiner">
    <div class="bill-tables">
      <div class="heading">
        <h3>Bills and Invoices</h3>
        <DatePicker
          class="datepicker-calendar"
          v-model="time"
          range
          format="YYYY-MM-DD"
          placeholder="Select Date Range"
          value-type="format"
          @change="relDate($event)"
        ></DatePicker>
      </div>
      <div class="bill-tables-placeholder" v-if="showBillItems">
        <div class="bill-tables-placeholder-cards loading" v-for="(item, index) in 4" :key="index"></div>
      </div>
      <table v-else-if="!showError" class="bill-items">
        <thead>
          <tr>
            <th>BILL DATE</th>
            <th>BILL ID</th>
            <th class="desc">DESCRIPTION</th>
            <th>DUE DATE</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in billDetails" :key="index">
            <td>{{ displayDateFormat(item.billfrom) }}</td>
            <td>{{ item.billid }}</td>
            <td class="desc">
              Have you ever finally just gave in to the temptation and read your
              horoscope
            </td>
            <td class="bright-text">{{ displayDateFormat(item.duedate) }}</td>
            <td class="bright-text">{{ item.invoiceamount }}</td>
            <td v-if="item.billstatus == 'PENDING'">
              <button class="button-primary paynow">{{$t("PAY NOW")}}</button>
            </td>
            <td v-else>{{ item.billstatus }}</td>
          </tr>
        </tbody>
      </table>

      <!-- <div>
        <Loading class="loader" v-if="showLoader" />
      </div>-->
      <div>
        <ErrorAlert class="error-alert" v-if="showError" :message="message"></ErrorAlert>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  data() {
    return {
      time: "",
      showmindate: "",
      showmaxdate: "",
      showLoader: true,
      showError: false,
      billDetails: [],
      showBillItems: true
    };
  },
  methods: {
    getInitialBills() {
      let payload = {
        fromdate: "",
        todate: ""
      };
      eventBus.$emit("list-all-bills", payload);
    },
    listInitialBills() {
      eventBus.$on("list-bills-response", payload => {
        this.showBillItems = false;
        this.showError = false;
        this.billDetails = payload;
      });
    },
    relDate(data) {
      console.log("relDate", data);
      let payload = {
        fromdate: data[0],
        todate: data[1]
      };
      this.showBillItems = true;
      this.billDetails = [];
      this.message = "";
      eventBus.$emit("list-all-bills", payload);
    },
    showError() {
      eventBus.$on("show-error-alert", payload => {
        this.showError = true;
        this.message = payload;
        this.billDetails = [];
        this.showBillItems = false;
      });
    }
  },
  mounted() {
    this.listInitialBills();
    this.showError// alert();
  },
  created() {
    this.getInitialBills();
  },
  components: {
    // Loading,
    ErrorAlert: () =>
      import(
        /* webpackChunkName: "ErrorAlert" */ "@/components/Templates/ErrorAlert.vue"
      ),
    DatePicker
  },
  mixins: [Utility]
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.bill-tables {
  padding: 2.5rem 0rem;
  &-placeholder {
    &-cards {
      border-radius: 0.6rem;
      margin: 0.5em 0em;
      background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
      height: 30px;
      overflow: hidden;
      width: 90%;
      margin: 1em auto;
    }
  }
  .heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $clr-light-gd3;
    margin: 0rem 0rem 1rem 3rem;
    width: 90%;
    .datePicker {
      position: absolute;
      top: 35%;
      right: 5%;
    }
    .billing-date {
      width: fit-content;
      padding: 6px;
      color: $clr-popup-label;
      background: transparent;
      border: 1px solid $clr-popup-label;
      border-radius: 2px;
    }
    .date-filters {
      display: flex;
      border-bottom: 1px solid $clr-light-gd4;
      color: $clr-light-gd4;
      font-size: 0.9rem;
      padding-bottom: 0.2rem;
      span {
        padding-right: 5px;
      }
    }
  }
}
.mx-datepicker {
  cursor: pointer;
  width: fit-content !important;
  .mx-input-wrapper {
    input {
      background: transparent;
      border: 1px solid $clr-popup-label;
      ::placeholder {
        font-family: $font-regular;
      }
    }
    .mx-icon-calendar {
      color: $clr-popup-label;
    }
  }
}
.bill-items {
  border: 1px solid $clr-popup-label;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;
  text-align: center;
  width: 90%;
  margin: 0rem 3rem 1rem;
  thead {
    border-color: inherit;
    border-collapse: separate;
  }
  tr {
    border-color: inherit;
  }
  th,
  td {
    border-left: 1px solid $clr-popup-label;
    padding: 17px;
  }
  th {
    color: $clr-popup-label;
    font-size: 0.8rem;
  }
  td {
    border-top: 1px solid $clr-popup-label;
    color: $clr-light-gd4;
    font-size: 0.8rem;
  }
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
  .desc {
    text-align: left;
    width: 35%;
  }
  .bright-text {
    color: $clr-light-gd3;
    opacity: 1;
  }
  .paynow {
    padding: 0.3rem;
    font-size: 0.7rem;
  }
}
.loader,
.error-alert {
  margin: 2em 0em;
}
</style>
